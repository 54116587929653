import { createGatsbyPageComponent } from '@shapeable/ui';
import { graphql } from 'gatsby';
export default createGatsbyPageComponent('Page');

export const QUERY = graphql`
  query PageQuery($id: ID!) {
    platform {
      page(id: $id) {
        id slug path 
        name name_fr name_de
        title title_fr title_de
        pretitle pretitle_fr pretitle_de
        subtitle subtitle_fr subtitle_de
        published
        content { text }
        content_fr { text }
        content_de { text }
        options { text }
        openGraph { title description { plain } image { thumbnails { full { url url2x } } } }
        intro { plain text }
        intro_fr { plain text }
        intro_de { plain text }
        outro { text }
        outro_fr { text }
        outro_de { text }
        orderNumber
        themeMode { slug }
        bannerThemeMode { slug }
        layout {
          id name slug component 
        }
        videos {
          id name url
          thumbnail { id url url2x }
        }
        imageAssets {
          id name image { id url url2x }
        }
        people {
          id name slug path
          organisation { id name }
          linkedin
          position
          photo { id url url2x }
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          pages {
            id name slug path
            openGraph {
              title description { plain }
              image { url url2x }
            }
          }
          organisations {
            __typename id name slug
            openGraph { image { url } }
            _schema { label pluralLabel }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url }
          }
          imageAssets {
            id
            slug
            image {
              id url width height url2x
            }
          }
        }
        
        parent {
          __typename id name path slug title subtitle pretitle 
          content { text }
          children {
            __typename id name path slug title subtitle pretitle 
            openGraph { description { text } }
            intro { text }
            orderNumber
            banner {
              name slug alternateText
              thumbnail { 
                id url url2x width height
              }
            }
          }
        }

        featurePosts {
          __typename id 
          name 
          name_fr
          name_de
          path slug 
          title 
          title_fr
          title_de
          subtitle 
          subtitle_fr
          subtitle_de
          pretitle 
          pretitle_fr
          pretitle_de
          openGraph { description { plain } image { url url2x } }
          intro { text }
          intro_fr { text }
          intro_de { text }
          videos {
            id name url
            thumbnail { id url url2x }
          }
          imageAssets {
            id name image { id url width height url2x }
          }
          banner {
            name slug alternateText
            thumbnail { 
              id url url2x width height
            }
          }
        }
        marqueePages {
          __typename id 
          name
          name_fr
          name_de
          path slug 
          title 
          title_fr
          title_de
          subtitle 
          subtitle_fr
          subtitle_de 
          pretitle 
          pretitle_fr
          pretitle_de
          openGraph { description { plain } }
          intro { text }
          intro_fr { text }
          intro_de { text }
          orderNumber
          videos {
            id name url
            thumbnail { id url url2x }
          }
          layout { name slug }
          banner {
            name slug alternateText
            thumbnail { 
              id url width height url2x
            }
          }
        }
        children {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { text } }
          intro { text }
          orderNumber
          banner {
            name slug alternateText
            thumbnail { 
              id url width height url2x
            }
          }
        }
        banners {
          id name slug title description { text } alternateText
          image { 
            id url url2x width height
          }
        }
        slices {
          id name slug 
          subtitle
          subtitle_fr
          subtitle_de
          title
          title_fr 
          title_de
          created updated
          outro { text }
          outro_fr { text }
          outro_de { text }
          intro { text }
          intro_fr { text }
          intro_de { text }
          themeMode { slug }
          images {
            id name slug alternateText description { text }
            image { 
              id url url2x width height 
            }
          }
          images_fr {
            id name slug alternateText description { text }
            image { 
              id url url2x width height
            }
          }
          images_de {
            id name slug alternateText description { text }
            image { 
              id url url2x width height
            }
          }
          pages {
            id name name_fr name_de path disabled
          }
          people {
            __typename
            id name slug photo { url url2x }
            position
            bio { text html(links: { target: "_blank" }) }
            bio_fr { text html(links: { target: "_blank" }) }
            bio_de { text html(links: { target: "_blank" }) }
            linkedin
            organisation { id name }
          }
          links {
            id name page { id path } file { file { url } } label label_fr label_de url url_fr url_de isDownload
          }
          options { text }
          files {
            id
            name
            slug
            file {
              id
              url
            }
            thumbnail {
              url url2x
            }
          }
          color { value }
          backgroundColor { value }
          backgroundImages {
            image {
              url url2x
            }
          }
          blocks {
            id name slug 
            title 
            title_fr 
            title_de
            subtitle
            subtitle_fr
            subtitle_de
            link { id url url_fr url_de name label label_fr label_de isDownload page { id path } file { file { url } } }
            page { id path name }
            links { id url url_fr url_de name label label_fr label_de isDownload page { id path } file { file { url } } }
            pages { id name }
            content { text }
            content_fr { text }
            content_de { text }
            options { text }
            image { id name image { url url2x } }
            image_fr { id name image { url url2x } }
            image_de { id name image { url url2x } }
          }
          content { text }
          content_fr { text }
          content_de { text }
          image { id }
          layout { id name slug component }
          organisations {
            id
            url
            logo {
              url url2x
            }
          }
        }
      }
    }
  }
`;
